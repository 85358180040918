@tailwind base;
@tailwind components;
@tailwind utilities;

.arrow {
    opacity: 0.1;
}

.arrow.active {
    opacity: 0.3;
}

.arrow:hover {
    opacity: 0.5;
}

.svg-container {
    width: 100%;
    overflow: scroll;
}

.logo {
    max-width: 125px;
}

select {
    /* text-indent: -2px; */
}

input[type=checkbox]
{
    /* Double-sized Checkboxes */
    -ms-transform: scale(0.5); /* IE */
    -moz-transform: scale(0.5); /* FF */
    -webkit-transform: scale(0.5); /* Safari and Chrome */
    -o-transform: scale(0.5); /* Opera */
    transform: scale(0.5);
    padding: 2px;
}
@media (min-width: 240px) {
    input[type=checkbox]{
      padding: 3px;
      -ms-transform: scale(1); /* IE */
      -moz-transform: scale(1); /* FF */
      -webkit-transform: scale(1); /* Safari and Chrome */
      -o-transform: scale(1); /* Opera */
      transform: scale(1);

    } 
}
@media (min-width: 640px) {
    input[type=checkbox]{
      padding: 8px;
      -ms-transform: scale(2); /* IE */
      -moz-transform: scale(2); /* FF */
      -webkit-transform: scale(2); /* Safari and Chrome */
      -o-transform: scale(2); /* Opera */
      transform: scale(2);

    } 
}

rect, rect:focus { outline: none }

polygon, polygon:focus { outline: none }


  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .footer p, .footer a {
    font-size: 10px;
    color: #888888;
    text-decoration: none;
  }